import React from 'react'
import SEO from '~/components/seo'
import { IframeWrapper, TumblrHeader, TumblrFooter } from '../utils/styles'
import { BrowserView, MobileView } from 'react-device-detect'
import Navigation from '../components/Navigation'
import {} from '../utils/styles'
import Newsletter from '../components/Newsletter'

const Subscribe = () => (
  <>
    <SEO
      title="BDST | BY BEN TAVERNITI | Bureau de Stil"
      keywords={[
        `bureau de Stil`,
        `ben tavertini`,
        `kim kardashian`,
        'LA Fashion',
        'fashion',
        'joyce bonelli',
        'unravel project',
        'trend brand',
        'online shop',
      ]}
    />

    <MobileView>
      <Navigation />
      <Newsletter />
    </MobileView>
  </>
)

export default Subscribe
